<template>
  <div v-if="me?.role?.name === constants.ROLES.ADMIN" class="flex-container scrollable">
    <DashboardAdminV2></DashboardAdminV2>
    <div class="spacer"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DashboardAdminV2 from "@/components/Dashboard/DashboardAdminV2";

export default {
  components: {DashboardAdminV2},
  computed: {
    ...mapState({
      me: state => state.auth.me,
      constants: state => state.constants
    })
  },
  data () {
    return {
      active: 0,
    }
  }
}
</script>

<style scoped>

</style>
