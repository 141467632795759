<template>
  <svg width="100%" height="100%" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.2359 13.5C28.2359 20.8123 22.1658 26.75 14.6655 26.75C7.16519 26.75 1.09506 20.8123 1.09506 13.5C1.09506 6.18772 7.16519 0.25 14.6655 0.25C22.1658 0.25 28.2359 6.18772 28.2359 13.5Z" stroke="#495057" stroke-width="0.5"/>
    <path d="M18.4347 20.8145V19.3418C18.4347 18.5606 18.117 17.8114 17.5515 17.2591C16.986 16.7067 16.219 16.3964 15.4193 16.3964H9.38859C8.58887 16.3964 7.8219 16.7067 7.25641 17.2591C6.69092 17.8114 6.37323 18.5606 6.37323 19.3418V20.8145" stroke="#495057" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.404 13.4509C14.0693 13.4509 15.4193 12.1322 15.4193 10.5055C15.4193 8.87872 14.0693 7.56 12.404 7.56C10.7386 7.56 9.38861 8.87872 9.38861 10.5055C9.38861 12.1322 10.7386 13.4509 12.404 13.4509Z" stroke="#495057" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.9578 20.8145V19.3418C22.9573 18.6892 22.7349 18.0552 22.3256 17.5394C21.9162 17.0236 21.3431 16.6552 20.6962 16.4921" stroke="#495057" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.6809 7.6557C18.3295 7.81792 18.9044 8.1864 19.3149 8.70304C19.7255 9.21968 19.9483 9.85509 19.9483 10.5091C19.9483 11.1631 19.7255 11.7985 19.3149 12.3152C18.9044 12.8318 18.3295 13.2003 17.6809 13.3625" stroke="#495057" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "UsersActives"
}
</script>

<style scoped>

</style>
