<template>
  <svg width="100%" height="100%" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.2359 13.5C28.2359 20.8123 22.1658 26.75 14.6655 26.75C7.16522 26.75 1.09509 20.8123 1.09509 13.5C1.09509 6.18772 7.16522 0.25 14.6655 0.25C22.1658 0.25 28.2359 6.18772 28.2359 13.5Z" stroke="#495057" stroke-width="0.5"/>
    <path d="M17.6808 20.8145V19.3418C17.6808 18.5606 17.3632 17.8114 16.7977 17.2591C16.2322 16.7067 15.4652 16.3964 14.6655 16.3964H9.38859C8.58887 16.3964 7.8219 16.7067 7.25641 17.2591C6.69092 17.8114 6.37323 18.5606 6.37323 19.3418V20.8145" stroke="#495057" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.0271 13.4509C13.6924 13.4509 15.0424 12.1322 15.0424 10.5055C15.0424 8.87872 13.6924 7.56 12.0271 7.56C10.3617 7.56 9.01169 8.87872 9.01169 10.5055C9.01169 12.1322 10.3617 13.4509 12.0271 13.4509Z" stroke="#495057" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20.6962 11.2418V15.66" stroke="#495057" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.9577 13.4509H18.4347" stroke="#495057" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "UsersNew"
}
</script>

<style scoped>

</style>
