<template>
  <svg width="100%" height="100%" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.1303 13.5C28.1303 20.8123 22.0602 26.75 14.5599 26.75C7.05957 26.75 0.989441 20.8123 0.989441 13.5C0.989441 6.18772 7.05957 0.25 14.5599 0.25C22.0602 0.25 28.1303 6.18772 28.1303 13.5Z" stroke="#495057" stroke-width="0.5"/>
    <path d="M20.4384 8.75L8.82922 19.25" stroke="#495057" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.0731 11.75C11.218 11.75 12.1461 10.9105 12.1461 9.875C12.1461 8.83947 11.218 8 10.0731 8C8.92814 8 8 8.83947 8 9.875C8 10.9105 8.92814 11.75 10.0731 11.75Z" stroke="#495057" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.1945 20C20.3394 20 21.2676 19.1605 21.2676 18.125C21.2676 17.0895 20.3394 16.25 19.1945 16.25C18.0496 16.25 17.1215 17.0895 17.1215 18.125C17.1215 19.1605 18.0496 20 19.1945 20Z" stroke="#495057" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "ConversionRate"
}
</script>

<style scoped>

</style>
