<template>
  <svg width="100%" height="100%" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.1303 13.5C28.1303 20.8123 22.0602 26.75 14.5599 26.75C7.05957 26.75 0.989441 20.8123 0.989441 13.5C0.989441 6.18772 7.05957 0.25 14.5599 0.25C22.0602 0.25 28.1303 6.18772 28.1303 13.5Z" stroke="#495057" stroke-width="0.5"/>
    <path d="M20.2723 18V16.6667C20.2723 15.9594 19.9617 15.2811 19.4088 14.781C18.8558 14.281 18.1059 14 17.324 14H11.4272C10.6453 14 9.89536 14.281 9.34244 14.781C8.78951 15.2811 8.47888 15.9594 8.47888 16.6667V18" stroke="#495057" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.3756 11.3333C16.0039 11.3333 17.324 10.1394 17.324 8.66667C17.324 7.19391 16.0039 6 14.3756 6C12.7473 6 11.4272 7.19391 11.4272 8.66667C11.4272 10.1394 12.7473 11.3333 14.3756 11.3333Z" stroke="#495057" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "Prospect"
}
</script>

<style scoped>

</style>
