<template>
  <Dialog
      v-model:visible="dialogActive"
      class="dialog-file-preview"
      :breakpoints="{'960px': '75vw', '640px': '90vw'}"
      :style="{width: '50vw'}"
      :draggable="false"
      :close-on-escape="false"
      modal
      :pt="{
        header: { class: 'align-items-start text-center' },
      }"
      @hide="closeDialog"
  >
    <template #header>
      <div style="width: 100%; text-align: center;">
        <h3>Facturation</h3>
      </div>
    </template>
    <template #closeicon>
      <Button icon="pi pi-times" class="p-dialog-header-icon p-dialog-header-close p-link mr-0" @click="closeDialog"/>
    </template>
    <div class="flex flex-column gap-2">
      <DashboardLine>
        <template #label>
          <div class="font-bold text-lg">CA Facturé HT</div>
        </template>
        <template #value>
          <div class="font-bold text-lg">{{ `${getEuroFormat(invoiceDashboardDetails.invoicedHt)}` }}</div>
        </template>
        <template #details>
          <DashboardLine detailed no-divider>
            <template #label>
              <div class="font-italic">Délai moyen de facturation</div>
            </template>
            <template #value>
              <div class="font-bold">{{ `${invoiceDashboardDetails.detailInvoicedAverageDays} jour${invoiceDashboardDetails.detailInvoicedAverageDays > 0 ? 's' : ''}` }}</div>
            </template>
          </DashboardLine>
        </template>
      </DashboardLine>
      <DashboardLine>
        <template #label>
          <div class="font-bold text-lg">CA Réglé HT</div>
        </template>
        <template #value>
          <div class="font-bold text-lg" style="color: #6E8F7A">{{ `${getEuroFormat(invoiceDashboardDetails.paidHt)} (${invoiceDashboardDetails.paidHtRatio} %)` }}</div>
        </template>
        <template #details>
          <DashboardLine detailed no-divider>
            <template #label>
              <div class="font-italic">Délai moyen de règlement</div>
            </template>
            <template #value>
              <div class="font-bold">{{ `${invoiceDashboardDetails.detailPaidInvoicedAverageDays} jour${invoiceDashboardDetails.detailPaidInvoicedAverageDays > 0 ? 's' : ''}` }}</div>
            </template>
          </DashboardLine>
        </template>
      </DashboardLine>
      <DashboardLine>
        <template #label>
          <div class="font-bold text-lg">CA en attente HT</div>
        </template>
        <template #value>
          <div class="font-bold text-lg" style="color: #DE911D">{{ `${getEuroFormat(invoiceDashboardDetails.waitingToPaidHt)} (${invoiceDashboardDetails.waitingToPaidHtRatio} %)` }}</div>
        </template>
        <template #details>
          <DashboardLine detailed no-divider>
            <template #label>
              <div class="font-italic"> &lt; 15 jours </div>
            </template>
            <template #value>
              <div>{{ getEuroFormat(invoiceDashboardDetails.detailWaitingInvoiceLteFifteen) }}</div>
            </template>
          </DashboardLine>
          <DashboardLine detailed no-divider>
            <template #label>
              <div class="font-italic">Entre 16 et 30 jours</div>
            </template>
            <template #value>
              <div>{{ getEuroFormat(invoiceDashboardDetails.detailWaitingInvoiceGteSixteenAndLteThirty) }}</div>
            </template>
          </DashboardLine>
          <DashboardLine detailed no-divider>
            <template #label>
              <div class="font-italic">Entre 31 et 41 jours</div>
            </template>
            <template #value>
              <div>{{ getEuroFormat(invoiceDashboardDetails.detailWaitingInvoiceGtThirtyAndLtFortyTwo) }}</div>
            </template>
          </DashboardLine>
          <DashboardLine detailed no-divider>
            <template #label>
              <div class="font-italic">+ 45 jours</div>
            </template>
            <template #value>
              <div>{{ getEuroFormat(invoiceDashboardDetails.detailWaitingInvoiceGteFortyFive) }}</div>
            </template>
          </DashboardLine>
        </template>
      </DashboardLine>
      <DashboardLine>
        <template #label>
          <div class="font-bold text-lg">CA en retard HT</div>
        </template>
        <template #value>
          <div class="font-bold text-lg" style="color: #B54D4D">{{ `${getEuroFormat(invoiceDashboardDetails.lateToPaidHt)} (${invoiceDashboardDetails.lateToPaidHtRatio} %)` }}</div>
        </template>
        <template #details>
          <DashboardLine detailed no-divider>
            <template #label>
              <div class="font-italic"> &lt; 15 jours </div>
            </template>
            <template #value>
              <div>{{ getEuroFormat(invoiceDashboardDetails.detailLateInvoiceLteFifteen) }}</div>
            </template>
          </DashboardLine>
          <DashboardLine detailed no-divider>
            <template #label>
              <div class="font-italic">Entre 16 et 30 jours</div>
            </template>
            <template #value>
              <div>{{ getEuroFormat(invoiceDashboardDetails.detailLateInvoiceGteSixteenAndLteThirty) }}</div>
            </template>
          </DashboardLine>
          <DashboardLine detailed no-divider>
            <template #label>
              <div class="font-italic">Entre 31 et 41 jours</div>
            </template>
            <template #value>
              <div>{{ getEuroFormat(invoiceDashboardDetails.detailLateInvoiceGtThirtyAndLtFortyTwo) }}</div>
            </template>
          </DashboardLine>
          <DashboardLine detailed no-divider>
            <template #label>
              <div class="font-italic">+ 45 jours</div>
            </template>
            <template #value>
              <div>{{ getEuroFormat(invoiceDashboardDetails.detailLateInvoiceGteFortyFive) }}</div>
            </template>
          </DashboardLine>
        </template>
      </DashboardLine>
      <DashboardLine no-divider>
        <template #label>
          <div class="font-bold text-lg">TVA</div>
        </template>
        <template #value>
          <div class="font-bold text-lg">{{ getEuroFormat(invoiceDashboardDetails.totalTva) }}</div>
        </template>
        <template #details>
          <DashboardLine detailed no-divider>
            <template #label>
              <div class="font-italic">TVA collectée</div>
            </template>
            <template #value>
              <div>{{ getEuroFormat(invoiceDashboardDetails.detailCollectedTva) }}</div>
            </template>
          </DashboardLine>
          <DashboardLine detailed no-divider>
            <template #label>
              <div class="font-italic">TVA déductible</div>
            </template>
            <template #value>
              <div>{{ getEuroFormat(invoiceDashboardDetails.detailDeductibleTva) }}</div>
            </template>
          </DashboardLine>
          <DashboardLine detailed no-divider>
            <template #label>
              <div class="font-italic">TVA collectée en attente</div>
            </template>
            <template #value>
              <div style="color: #DE911D">{{ getEuroFormat(invoiceDashboardDetails.detailCollectedWaitingTva) }}</div>
            </template>
          </DashboardLine>
          <DashboardLine detailed no-divider>
            <template #label>
              <div class="font-italic">TVA déductible en attente</div>
            </template>
            <template #value>
              <div style="color: #DE911D">{{ getEuroFormat(invoiceDashboardDetails.detailDeductibleWaitingTva) }}</div>
            </template>
          </DashboardLine>
        </template>
      </DashboardLine>
    </div>
  </Dialog>
</template>

<script>

import DashboardLine from "@/components/Dashboard/DashboardLine.vue";
import {getEuroFormat} from "@/utils/Money";

export default {
  name: "DashboardDetails",
  components: {DashboardLine},
  emits: ['closeDialog'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    invoiceDashboardDetails: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialogActive: false,
      loading: false,
    }
  },
  watch: {
    isActive (value) {
      this.dialogActive = value
    }
  },
  methods: {
    getEuroFormat,
    closeDialog () {
      console.log('closeDialog')
      this.$emit('closeDialog')
    }
  }
}
</script>
<style scoped>

</style>
