<template>
  <div class="flex flex-column gap-4">
    <div class="flex flex-row justify-content-between align-items-end px-3">
      <div class="flex flex-row gap-2">
        <CalendarRange :model-value="selectedDates" class="h-fit" @update:model-value="getData"/>
        <Button :label="$t('export')" icon="pi pi-external-link" @click="exportGlobalSynthesisSheet"/>
      </div>
      <bloc-cagnotte v-if="!loading" :custom-values="globalPot"/>
    </div>

    <div v-if="!loading" class="grid gap-2 px-3">
      <!-- todo: nombre de porte ON_GOING / OUT_GOING sur la période -->
      <independent-statistics :model-value="summary.activeIndependentCount" label="Portés Actifs à ce jour">
        <template #icon>
          <UsersActives style="width: 50px"/>
        </template>
      </independent-statistics>
      <!-- todo: nombre de porte NEW / ON_GOING / OUT_GOING, OUT sur la période -->
      <independent-statistics :model-value="summary.totalIndependentCount" label="Portés Total">
        <template #icon>
          <UsersChecked style="width: 50px"/>
        </template>
      </independent-statistics>
      <!-- todo: nombre de porte crées et NEW sur la période (simulations ou portés ?) -->
      <independent-statistics :model-value="summary.newIndependentCount" label="Nouveaux portés">
        <template #icon>
          <UsersNew style="width: 50px"/>
        </template>
      </independent-statistics>
      <!-- todo: nombre de porte crées sur la période (simulations ou portés ?) -->
      <independent-statistics :model-value="summary.prospectIndependentCount" label="Prospects">
        <template #icon>
          <Prospect style="width: 50px"/>
        </template>
      </independent-statistics>
      <!-- todo: ratio des portés crées sur la période sur le nombre de portés crées et dont le status est NEW -->
      <independent-statistics :model-value="summary.prospectRateConversionIndependent + '%'" label="Taux de conversion">
        <template #icon>
          <ConversionRate style="width: 50px"/>
        </template>
      </independent-statistics>
    </div>

    <div v-if="!loading" class="grid gap-2 px-3">
      <DashboardGlobalCard title="Facturation">
        <template #content>
          <DashboardLine>
            <template #label>
              <div>CA Facturé HT</div>
            </template>
            <template #value>
              <div class="font-bold">{{ getEuroFormat(summary.invoicedHt) }}</div>
            </template>
          </DashboardLine>

          <DashboardLine>
            <template #label>
              <div>CA Réglé HT</div>
            </template>
            <template #value>
              <div class="font-bold" style="color: #6E8F7A">{{ getEuroFormat(summary.paidHt) }}</div>
            </template>
          </DashboardLine>

          <DashboardLine>
            <template #label>
              <div>CA en attente HT</div>
            </template>
            <template #value>
              <div class="font-bold" style="color: #DE911D">{{ getEuroFormat(summary.waitingToPaidHt) }}</div>
            </template>
          </DashboardLine>

          <DashboardLine>
            <template #label>
              <div>CA en retard HT</div>
            </template>
            <template #value>
              <div class="font-bold" style="color: #B54D4D">{{ getEuroFormat(summary.lateToPaidHt) }}</div>
            </template>
          </DashboardLine>

          <DashboardLine>
            <template #label>
              <div>TVA</div>
            </template>
            <template #value>
              <div class="font-bold">{{ getEuroFormat(summary.totalTva) }}</div>
            </template>
          </DashboardLine>

          <div class="flex justify-content-end">
            <Button label="Détails" @click="openInvoiceDetails"/>
          </div>
        </template>
      </DashboardGlobalCard>
      <DashboardGlobalCard title="Commission de gestion" card-class="col-12 md:col-10 lg:col-8 xxl:col-4">
        <template #content>
          <DashboardLine>
            <template #label>
              <div>Taux moyen de gestion</div>
            </template>
            <template #value>
              <div class="font-bold">{{ summary.managementFeeAverage + '%' }}</div>
            </template>
          </DashboardLine>

          <DashboardLine>
            <template #label>
              <div>Commission sur CA HT réglé</div>
            </template>
            <template #value>
              <div class="font-bold" style="color: #6E8F7A">{{ getEuroFormat(summary.managementFeePaidAmount) }}</div>
            </template>
          </DashboardLine>

          <DashboardLine>
            <template #label>
              <div>Commissions sur CA HT en attente de règlement</div>
            </template>
            <template #value>
              <div class="font-bold" style="color: #DE911D">{{ getEuroFormat(summary.managementFeeWaitingAmount) }}</div>
            </template>
          </DashboardLine>

          <DashboardLine>
            <template #label>
              <div>Commissions sur CA HT en retard de paiement</div>
            </template>
            <template #value>
              <div class="font-bold" style="color: #B54D4D">{{ getEuroFormat(summary.managementFeeLateAmount) }}</div>
            </template>
          </DashboardLine>

          <DashboardLine no-divider>
            <template #label>
              <div class="font-bold">Total</div>
            </template>
            <template #value>
              <div class="font-bold">{{ getEuroFormat(summary.managementFeeAmount) }}</div>
            </template>
          </DashboardLine>
        </template>
      </DashboardGlobalCard>
      <DashboardGlobalCard title="Salaires">
        <template #content>
          <DashboardLine>
            <template #label>
              <div>Coût global</div>
            </template>
            <template #value>
              <div class="font-bold">{{ getEuroFormat(summary.globalCompanyCostTotal) }}</div>
            </template>
          </DashboardLine>

          <DashboardLine>
            <template #label>
              <div>Net à payer</div>
            </template>
            <template #value>
              <div class="font-bold">{{ getEuroFormat(summary.netToPayTotal) }}</div>
            </template>
          </DashboardLine>

          <DashboardLine>
            <template #label>
              <div>Cotisations sociales</div>
            </template>
            <template #value>
              <div class="font-bold">{{ getEuroFormat(summary.salaryExpenseTotal) }}</div>
            </template>
          </DashboardLine>

          <DashboardLine>
            <template #label>
              <div>Prélèvement à la source</div>
            </template>
            <template #value>
              <div class="font-bold">{{ getEuroFormat(summary.taxableRevenueTotal) }}</div>
            </template>
          </DashboardLine>

<!--          <DashboardLine style="visibility: hidden">-->
<!--            <template #label>-->
<!--              <div>a</div>-->
<!--            </template>-->
<!--            <template #value>-->
<!--              <div class="font-bold">a</div>-->
<!--            </template>-->
<!--          </DashboardLine>-->
        </template>
      </DashboardGlobalCard>
      <DashboardGlobalCard title="Frais/Dispositifs sociaux/Achats Fournisseurs">
        <template #content>
          <DashboardLine>
            <template #label>
              <div>NDF</div>
            </template>
            <template #value>
              <div class="font-bold">{{ getEuroFormat(summary.ndfTypeRefundedAmount) }}</div>
            </template>
          </DashboardLine>

          <DashboardLine>
            <template #label>
              <div>NDFR</div>
            </template>
            <template #value>
              <div class="font-bold">{{ getEuroFormat(summary.ndfrTypeRefundedAmount) }}</div>
            </template>
          </DashboardLine>

          <DashboardLine>
            <template #label>
              <div>Dispositifs sociaux</div>
            </template>
            <template #value>
              <div class="font-bold">{{ getEuroFormat(summary.socialMeasuresTypeRefundedAmount) }}</div>
            </template>
          </DashboardLine>

          <DashboardLine>
            <template #label>
              <div>Achats fournisseurs</div>
            </template>
            <template #value>
              <div class="font-bold">{{ getEuroFormat(summary.supplierInvoiceRefundedAmount) }}</div>
            </template>
          </DashboardLine>

          <DashboardLine no-divider>
            <template #label>
              <div class="font-bold">Total</div>
            </template>
            <template #value>
              <div class="font-bold">{{ getEuroFormat(summary.globalNdfTotal) }}</div>
            </template>
          </DashboardLine>
        </template>
      </DashboardGlobalCard>
    </div>
  </div>
  <DashboardDetails v-if="!loading" :invoice-dashboard-details="summary" :is-active="dashboardDetailsDialog" @close-dialog="closeInvoiceDetails"/>
</template>

<script>
import CalendarRange from "@/components/CalendarRange.vue";
import IndependentStatistics from "@/components/Dashboard/IndependentStatistics.vue";
import UsersActives from "@/components/Icons/UsersActives.vue";
import UsersChecked from "@/components/Icons/UsersChecked.vue";
import UsersNew from "@/components/Icons/UsersNew.vue";
import Prospect from "@/components/Icons/Prospect.vue";
import ConversionRate from "@/components/Icons/ConversionRate.vue";
import {getEuroFormat} from "@/utils/Money";
import DashboardLine from "@/components/Dashboard/DashboardLine.vue";
import BlocCagnotte from "@/components/RH/BlocCagnotte.vue";
import DashboardDetails from "@/components/Dashboard/DashboardDetails.vue";
import DashboardGlobalCard from "@/components/Dashboard/DashboardGlobalCard.vue";
import isBrowserAppMixin from "@/mixins/isBrowserAppMixin";

export default {
  name: "DashboardAdminV2",
  mixins: [isBrowserAppMixin],
  components: {
    DashboardGlobalCard,
    DashboardDetails,
    BlocCagnotte,
    DashboardLine, ConversionRate, Prospect, UsersNew, UsersChecked,
    UsersActives, IndependentStatistics, CalendarRange
  },
  data () {
    return {
      selectedDates: [null, null],
      dashboardDetailsDialog: false,
      summary: null,
      globalPot: null,
      loading: true
    }
  },
  async created () {
    this.selectedDates = [null, null]
    const rangeStartDate = new Date()
    this.selectedDates[0] = new Date(rangeStartDate.getFullYear(), 0, 1)
    this.selectedDates[1] = new Date()

    await this.getData(this.selectedDates)
  },
  methods: {
    getEuroFormat,
    async getData (range) {
      this.loading = true
      console.log('getData', this.getIsBrowserApp)
      if (this.getIsBrowserApp) {
        this.summary = await this.$store.dispatch('misc/getGlobalSummary', { range })
        this.globalPot = {
          treasury: this.summary.treasury,
          waitingToPay: this.summary.waitingTreasury,
          pot: this.summary.pot
        }
        console.log(this.globalPot)
      }
      this.loading = false
    },
    openInvoiceDetails () {
      this.dashboardDetailsDialog = true
    },
    closeInvoiceDetails () {
      this.dashboardDetailsDialog = false
    },
    exportGlobalSynthesisSheet () {
      // SheetGeneratorRefactor({ ids: [], range: this.selectedDates }, `/independent/export-sheet-synthesis/${this.independent.id}`, this)
      //     .then(() => {
      //       this.exportSheetLoading = false
      //     })
      //     .catch(() => {
      //       this.exportSheetLoading = false
      //     })
    }
  }
}
</script>

<style scoped>
</style>
