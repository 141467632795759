<template>
  <div :class="cardClass" class="flex flex-column flex-grow-1 card mb-0 shadow-2 gap-2 px-4 py-3 col-12 md:col-6 lg:col-4 xxl:col-2">
    <div class="text-center h-3rem md:h-7rem">
      <h3>{{ title }}</h3>
    </div>
    <slot name="content"/>
  </div>
</template>

<script>
export default {
  name: "DashboardGlobalCard",
  props: {
    cardClass: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
