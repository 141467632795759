<template>
  <div class="flex gap-2 align-items-center" :class="textTemplateHelper ? 'px-2' : ''">
    <div v-if="textTemplateHelper"> Du </div>
    <Calendar
        v-model="rangeStart"
        class="p-button-sm justify-content-center"
        show-icon
        view="month"
        date-format="mm/yy"
        :manual-input="false"
        :max-date="new Date()"
        @update:modelValue="changeRangeStart"
    />
    <div v-if="textTemplateHelper"> au </div>
    <Calendar
        v-model="rangeEnd"
        class="p-button-sm justify-content-center"
        show-icon
        view="month"
        date-format="mm/yy"
        :manual-input="false"
        :min-date="rangeStart"
        :max-date="new Date()"
        @update:modelValue="updateModelValue"
    />
  </div>
</template>

<script>
export default {
  name: "CalendarRange",
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Array,
      required: false
    },
    autoRangeDefault: {
      type: Boolean,
      default: true
    },
    textTemplateHelper: {
      type: Boolean,
      default: true
    },
    autoFirstUpdate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rangeStart: null,
      rangeEnd: null
    }
  },
  created () {
    this.init(this.modelValue)
    if (this.autoFirstUpdate) {
      this.updateModelValue()
    }
  },
  watch: {
    modelValue (value) {
      this.init(value)
    }
  },
  methods: {
    init (value) {
      let rangeEndDate = new Date()
      let rangeStartDate = new Date(rangeEndDate.getFullYear(), 0, 1)

      if (value) {
        rangeStartDate = value[0]
        rangeEndDate = value[1]
      }

      this.rangeStart = rangeStartDate
      this.rangeEnd = rangeEndDate
    },
    changeRangeStart () {
      if (this.rangeStart > this.rangeEnd) {
        this.rangeEnd = new Date()
      }
      this.updateModelValue()
    },
    updateModelValue () {
      this.$emit('update:modelValue', [this.rangeStart, this.rangeEnd])
    }
  }
}
</script>

<style scoped>

</style>
