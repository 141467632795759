<template>
  <div class="flex flex-row flex-grow-1 card px-4 py-4 mb-0 shadow-4 gap-2 col-12 md:col-6 lg:col-4 xxl:col-2">
    <slot name="icon"/>
    <div class="flex flex-column pt-2">
      <div class="text-xl">{{ label }}</div>
      <div class="text-xl font-bold">{{ modelValue }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndependentStatistics",
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
