<template>
  <div :class="detailed ? 'flex-grow-1' : ''" class="flex flex-column">
    <div class="flex flex-row justify-content-between">
      <div v-if="detailed" class="flex flex-row align-items-center gap-1">
        <div class="text-3xl font-bold mb-1"> > </div>
        <slot name="label"/>
      </div>
      <slot v-else name="label"/>
      <div v-if="detailed" class="flex flex-row align-items-center">
        <slot name="value"/>
      </div>
      <slot v-else name="value"/>
    </div>
    <div class="flex flex-column ml-2">
      <slot name="details"/>
    </div>
    <divider v-if="!noDivider" layout="horizontal" class="mt-2"/>
  </div>
</template>

<script>
import {getEuroFormat} from "@/utils/Money";

export default {
  name: "DashboardLine",
  props: {
    noDivider: {
      type: Boolean,
      default: false
    },
    detailed: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getEuroFormat

  }
}
</script>

<style scoped>

</style>
